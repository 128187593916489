

 

    export const CatalogList=[{
        id:1,
        name:'Bloosi Lamp',
        description:'Since our humble beginnings in 2006, LightHouse has significantly expanded its collection of exclusive',
        price:399,
        image:'https://images.unsplash.com/photo-1543512214-4f76e81f8bfc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGxhbXBzfGVufDB8fDB8fHww',
       },
       {
        id:2,
        name:'Urban Lamp',
        description:'Since our humble beginnings in 2006, LightHouse has significantly expanded its collection of exclusive',
        price:699,
        image:'https://images.unsplash.com/photo-1558618062-55384ca51256?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGxhbXBzfGVufDB8fDB8fHww',
       },
       {
        id:3,
        name:'Edisson Lamp',
        description:'Since our humble beginnings in 2006, LightHouse has significantly expanded its collection of exclusive',
        price:799,
        image:'https://images.unsplash.com/photo-1592195986398-5484c0890b74?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGxhbXBzfGVufDB8fDB8fHww',
       },
       {
        id:4,
        name:'Eglo Vintage',
        description:'Since our humble beginnings in 2006, LightHouse has significantly expanded its collection of exclusive',
        price:299,
        image:'https://images.unsplash.com/photo-1560851668-e96ae9cfe945?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGxhbXBzfGVufDB8fDB8fHww',
       },
       {
        id:5,
        name:'Chineses Lantern',
        description:'Since our humble beginnings in 2006, LightHouse has significantly expanded its collection of exclusive',
        price:499,
        image:'https://images.unsplash.com/photo-1587133966114-7f69b8803e1b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Y2hpbmVzZSUyMGxhbnRlcm58ZW58MHx8MHx8fDA%3D',
       },
       {
        id:6,
        name:'indian lantern',
        description:'Since our humble beginnings in 2006, LightHouse has significantly expanded its collection of exclusive',
        price:499,
        image:'https://images.unsplash.com/photo-1700196798611-d27af6d9c566?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGluZGlhbiUyMGxhbnRlcm58ZW58MHx8MHx8fDA%3D',
       }
       
    
    
    
    ]


    
 






